
import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunLifeItems'

const lifeItemsSaveApi =(params)=>postAction(baseURL+"/saveOrupdate",params);

const lifeItemsDeleteApi=(params)=>deleteByIdAction(baseURL+"/deleteById",params);

const lifeItemsSelectByApi=(params)=>getByIdAction(baseURL+"/selectById",params);

const lifeItemsLisyApi=(params)=>getAction(baseURL+"/list",params);

export{
    lifeItemsSaveApi,
    lifeItemsLisyApi,
    lifeItemsDeleteApi,
    lifeItemsSelectByApi
}