<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
      <div style="display: inline-block; float: right; cursor: pointer">
        <el-button-group class="card-toggle-table">
          <el-tooltip class="item" effect="dark" content="新增" placement="top-start">
            <el-button size="small" @click="addRecipeButton" icon="el-icon-circle-plus-outline"
              style="margin: 0px 2px;" />
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="库存" placement="top-start">
            <el-button size="small" @click="saveItemsButton" icon="el-icon-folder-add" style="margin: 0px 2px;" />
          </el-tooltip>
          <el-tooltip v-if="cardType" class="item" effect="dark" content="切换成表格" placement="top-start">
            <el-button size="small" plain icon="el-icon-s-grid" @click="toggle()" />
          </el-tooltip>
          <el-tooltip v-else class="item" effect="dark" content="切换成卡片" placement="top-start">
            <el-button size="small" plain icon="el-icon-bank-card" @click="toggle()" />
          </el-tooltip>
        </el-button-group>
      </div>
    </el-breadcrumb>


    <!-- 查询区域 -->
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-row :gutter="20">
        <el-col :span="7">
          <span class="demonstration">名称:</span>
          <el-select v-model="queryParams.name" placeholder="请选择" size="medium">
            <el-input />
          </el-select>
        </el-col>
        <el-col :span="7">
          <span class="demonstration">能制作:</span>
          <el-select v-model="queryParams.isCanMark" placeholder="请选择" clearable>
            <el-option v-for="item in isMarkList" :key="item.value" :label="item.showText" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="getFoodListButton">查询</el-button>
          <el-button type="primay" @click="clearIndexButton()">重置</el-button>
        </el-col>
      </el-row>
      <el-tab-pane label="调酒" ref="alcohol" name="alcohol" :key="'alcohol'">
        <showList ref="alcoholShowListModules" :roles="alcoholList" />
      </el-tab-pane>
      <!-- <el-tab-pane label="菜谱" ref="dishes" name="dishes" :key="'dishes'">
        <showList ref="dishesShowListModules" :roles="disheslList" />
      </el-tab-pane> -->

    </el-tabs>
    <addCookBook ref="recipdeModules" />
    <addItems ref="addItemsModules" />
  </div>
</template>
<script>
import {
  listFoodDataApi, listFoodDataAllApi
} from "@/api/life/life/food";
import addCookBook from "./modules/addCookBook.vue";
import addItems from "./modules/addItems.vue";
import showList from "./modules/showList.vue";
export default {
  name: "name",
  components: {
    addCookBook,
    addItems,
    showList
  },
  data() {
    return {
      queryParams: {
        currentPage: 1,
        pageSize: 450,
        total: 0,
      },
      cardType: true,
      activeName: "alcohol",
      isMarkList: [
        {
          showText: "是",
          value: true
        },
        {
          showText: "否",
          value: false
        },]
      ,
      // 角色列表
      alcoholList: [
      ],
      disheslList: [
        {
          description: "12",
        },
        {
          description: "12",
        },
      ],
      addTitile: "新增"
    };
  },
  created(){
    this.getFoodListButton()
  },
  methods: {
    // 点击切换风格
    toggle() {
      //不建议在切换的时候进行反复调用
      if (this.activeName === 'alcohol') {
        this.$refs.alcoholShowListModules.toggle(this.activeName);
      } else if (this.activeName === 'dishes') {
        this.$refs.dishesShowListModules.toggle(this.activeName);
      }
    },
    getFoodListButton() {
      console.log('reslut',this.queryParams.isCanMark)
      if(this.queryParams.isCanMark ==''||this.queryParams.isCanMark==undefined){
        this.queryParams.isCanMark=false
      }
     
      if (this.activeName === 'alcohol') {
        this.queryParams.category = 'xun.life.alcohol'

      }
      listFoodDataApi(this.queryParams).then(res => {
        this.alcoholList = res.result==null?[]:res.result.records
      })

    },
    //这是直接获取不同列表的tab数据 在初始化的时候进行展示 只有在最初的时候进行调用这个  后面的话 根据不同的type去调用getRolsListButton接口
    initAllListButton() {
      if(this.queryParams.isCanMark ==''||this.queryParams.isCanMark==undefined){
        this.queryParams.isCanMark=false
      }
      listFoodDataAllApi(this.queryParams).then(res=>{
          this.alcoholList = res.result.alcoholList==null?[]:res.result.alcoholList
      })

    },
    clearIndexButton() {
      this.queryParams = {}
    },
    addRecipeButton() {
      this.$refs.recipdeModules.show("", 1);
    },
    handleClickTab(tab, event) {
      console.log('-----', tab, event, this.activeName)
    },
    saveItemsButton() {
      this.$refs.addItemsModules.show("", 1);
    }
  },
};
</script>