<template>
    <div>
        <el-row v-if="cardType">
            <el-col v-for="item in roles" :key="item.id" :span="5" style="margin-bottom: 10px" :offset="1">
                <el-card style="width: 90%;height: 40%;">
                    <div slot="header" class="clearfix">
                        <!-- <i class="el-icon-user" /> -->
                        <span style="margin-left: 5px">
                            {{ item.name }}
                        </span>
                        <div style="display: inline-block; float: right; cursor: pointer" @click="doEdit(item)">
                            <el-tooltip effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit-outline" style="margin-left: 15px" />
                            </el-tooltip>
                        </div>
                        <div style="display: inline-block; float: right; cursor: pointer" @click="selectButton(item)">
                            <el-tooltip effect="dark" content="查看" placement="top">
                                <i class="el-icon-zoom-in" style="margin-left: 15px" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div @dblclick="handleDoubleClick">
                        <!-- <el-image :src="item.mainImages"></el-image> -->
                        <el-image :src="item.mainImages" class="role-info_images">
                            <!-- <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div> -->
                        </el-image>
                        <ul class="role-info">
                            <li class="role-info-li">
                                <div class="role-left">
                                    描述信息：{{ item.description }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <div style="display: inline-block; float: left; cursor: pointer" @click="test(item.id, item.roleName)">
                        <el-tooltip effect="dark" content="分享" placement="bottom">
                            <i class="el-icon-menu" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="test(item.id)">
                        <el-tooltip effect="dark" content="添加图片" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div> -->
                    <!-- <div style="display: inline-block; float: right; cursor: pointer" @click="test(item.id)">
                        <el-tooltip effect="dark" content="查看分析" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div> -->
                    <!-- <div style="display: inline-block; float: right; cursor: pointer" @click="test(item.id)">
                        <el-tooltip effect="dark" content="分享" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="delteButton(item)">
                        <el-tooltip effect="dark" content="删除角色" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div> -->
                </el-card>
            </el-col>
        </el-row>
        <!-- 如果cardType为false则显示列表风格 -->
        <el-table v-else ref="table" :data="roles" style="width: 100%; font-size: 12px"
            @selection-change="handleSelectionChange">
            <el-table-column :show-overflow-tooltip="true" width="150" prop="name" label="名称" />

            <el-table-column prop="description" label="描述信息" />
            <el-table-column label="操作" width="300" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" round @click="selectButton(scope.row)">查看</el-button>
                    <el-button size="mini" type="text" round @click="doEdit(scope.row)">编辑</el-button>
                    <!-- <el-button size="mini" type="text" round @click="delteButton(scope.row)">删除</el-button> -->
                    <!-- <el-button size="mini" type="text" round @click="test(scope.row.id)">分享内容</el-button> -->
                    <!-- <el-button size="mini" type="text" round @click="test(scope.row.id, scope.row.roleName)">添加图片</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <addCookBook ref="addCookBookModelues" />
    </div>
</template>
<script>
import {
    deleteFoodByIdApi
} from "@/api/life/life/food";
import addCookBook from "./addCookBook.vue";
export default {
    components: {
        addCookBook
    },
    props: {
        // 接收来自父组件的data对象，并解构出message属性
        roles: {
            type: Array, // 指定data应该是一个对象类型
            required: true // 如果这个prop是必须的，可以设置为true
        }
    },
    data() {
        return {
            cardType: true,
        }
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        toggle(activeName) {
            this.cardType = !this.cardType;
        },
        handleDoubleClick() {
            alert("您双击了这个div元素！");
        },
        test() {

        },
        handleClickTab(tab, event) { },
        doEdit(row) {
            this.$refs.addCookBookModelues.show(row, 2);
        },
        selectButton(row) {
            this.$refs.addCookBookModelues.show(row, 3);
        },
        delteButton(row) {
            deleteFoodByIdApi({ uniqueId: row.uniqueId }).then(res => {
                this.$parent.$parent.$parent.initAllListButton()
            })
        }
    }
}
</script>
<style lang="less" scoped>
.role-info_images{
    width: 100%;
    height: 280px;
    object-fit: contain;
}
.role-info{
    list-style: none;
    padding: 0;
}
.role-info-li{
    white-space: normal;
    word-break: break-work;
}
.el-image__inner {
    vertical-align: top;
    height: 280px;
    object-fit: contain;
}
ul.role-info {
    list-style: none;
    padding: 0;
}

/deep/ .el-card__body {
    padding: 20px;
    height: 400px;
}
</style> 