<template>
    <el-dialog title="资料列表" :visible.sync="outerVisible" width="80%" :before-close="outerVisibleHandleClose">
        <el-form :model="queryParams" ref="queryRef" :inline="true" label-width="68px">
            <el-form-item label="分类" prop="category">
                <el-select clearable v-model="queryParams.category" placeholder="请选择" @keyup.enter="lifeItemsListButton">
                    <el-option v-for="item in categoryList" :key="item.value" :label="item.text" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="二级分类" prop="categorySecond">
                <el-input v-model="queryParams.categorySecond"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="Search" @click="lifeItemsListButton">搜索</el-button>
                <el-button icon="Refresh" @click="resetQuery">重置</el-button>
            </el-form-item>
            <div style="display: inline-block; float: right; cursor: pointer">
                <el-button-group class="card-toggle-table">
                    <el-tooltip class="item" effect="dark" content="新增" placement="top-start">
                        <el-button size="small" @click="innerVisible = true" icon="el-icon-plus" style="margin: 0px 2px;" />
                    </el-tooltip>
                </el-button-group>
            </div>
        </el-form>
        <el-table :data="itemLiveList" height="300">
            <el-table-column label="编码" align="center" prop="code" />
            <el-table-column label="名称" align="center" prop="name" />
            <el-table-column label="分类" align="center" prop="category" :formatter="categoryFormat" />
            <el-table-column label="二级分类" align="center" prop="categorySecond" />
            <el-table-column label="品牌" align="center" prop="brand" />
            <el-table-column label="数量" align="center" prop="quantityName" />
            <el-table-column label="剩余" align="center" prop="remainingName" />
            <el-table-column label="存放位置" align="center" prop="location" />
            <el-table-column label="更新时间" align="center" prop="modifiedTime" />
            <el-table-column label="过期时间" align="center" prop="expirationDate" />
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" round @click="lifeItemsEditSaveButton(scope.row)">编辑</el-button>
                    <el-popconfirm title="是否删除" placement="top" confirm-button-text="好的" cancel-button-type="不用了"
                        icon="el-icon-info" icon-color="red" @confirm="lifeItemsDeleteButton(scope.row)">
                        <el-button slot="reference" size="mini" round>删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog width="80%" title="新增" append-to-body center :visible.sync="innerVisible"
            :before-close="innerVisibleHandleClose">
            <el-form ref="itemForm" :model="itemForm" label-width="80px">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="分类" prop="category">
                            <el-select v-model="itemForm.category" placeholder="请选择">
                                <el-option v-for="item in categoryList" :key="item.value" :label="item.text"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="二级分类" prop="categorySecond">
                            <el-input v-model="itemForm.categorySecond"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="编码" prop="code">
                            <el-input v-model="itemForm.code"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="二级分类" prop="name">
                            <el-input v-model="itemForm.name"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="itemForm.name"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="5">
                        <el-form-item label="品牌" prop="brand">
                            <el-input v-model="itemForm.brand"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="剩余" prop="remaining">
                            <el-input type="number" v-model="itemForm.remaining" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="存放位置" prop="location">
                            <el-input v-model="itemForm.location"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="数量" prop="quantity">
                            <el-input type="number" v-model="itemForm.quantity" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="单位" prop="unit">
                            <el-cascader ref="myEmnus" v-model="itemForm.unit" :options="options"
                                :show-all-levels="false"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="规格" prop="specs">
                            <el-input type="number" v-model="itemForm.specs" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单位" prop="specsUnit">
                            <el-cascader ref="myEmnus" v-model="itemForm.specsUnit" :options="options"
                                :show-all-levels="false"></el-cascader>
                        </el-form-item>
                    </el-col>

                </el-row>




                <el-row :gutter="24">


                    <el-col :span="16">
                        <el-form-item label="描述" prop="description">
                            <el-input v-model="itemForm.description"></el-input>
                        </el-form-item>
                    </el-col>


                </el-row>

                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="过期日期" prop="expirationDate">
                            <el-date-picker style="width: 100%" v-model="itemForm.expirationDate" align="right" type="date"
                                placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态" prop="status">
                            <el-select v-model="itemForm.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否常用" prop="isFrequentlyUsed">
                            <el-checkbox v-model="itemForm.isFrequentlyUsed" :true-label="1"
                                :false-label="2">是</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="是否提醒" prop="supplier">
                            <el-checkbox v-model="itemForm.reminder" :true-label="1" :false-label="2">是</el-checkbox>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row>
                    <font style="margin:0px 0px 10px 30px;" @click="moreButton">
                        {{ !this.moreSwitch ? "更多↓" : "收起↑" }}
                    </font>
                </el-row>
                <!-- moreSwitch -->
                <el-row :gutter="24" v-if="this.moreSwitch">
                    <el-col :span="8">
                        <el-form-item label="购买日期" prop="purchaseDate">
                            <el-date-picker v-model="itemForm.purchaseDate" align="right" type="date" placeholder="选择日期"
                                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="型号||款式" prop="model">
                            <el-input v-model="itemForm.model"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="链接" prop="supplier">
                            <el-input v-model="itemForm.supplier"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="补充日期" prop="lastRestockDate">
                            <el-date-picker v-model="itemForm.lastRestockDate" align="right" type="date" placeholder="选择日期"
                                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <el-row :gutter="24" v-if="this.moreSwitch">
                    <el-col :span="8">
                        <el-form-item label="单价" prop="price">
                            <el-input v-model="itemForm.price"></el-input>
                        </el-form-item>
                    </el-col>

                    <!-- <el-col :span="8">
                        <el-form-item label="标签" prop="tags">
                            <el-input v-model="itemForm.tags"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="产地" prop="producer">
                            <el-input v-model="itemForm.producer"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row :gutter="24" v-if="this.moreSwitch">
                    <el-col :span="8">
                        <el-form-item label="可用天数" prop="estimatedDurationDays">
                            <el-input v-model="itemForm.estimatedDurationDays"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="消耗速率" prop="usageRate">
                            <el-input v-model="itemForm.usageRate"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="消耗单位" prop="usageRateTime">
                            <el-input v-model="itemForm.usageRateTime"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- <el-row :gutter="24" v-if="this.moreSwitch">
                    <el-col :span="24">
                        <el-form-item label="详细描述" prop="description">
                            <el-input v-model="itemForm.description"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="innerVisibleClear">取 消</el-button>
                <el-button type="primary" @click="lifeItemsSaveButton()">确 定</el-button>
            </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
            <el-button @click="outerVisible = false">取 消</el-button>
        </div>
    </el-dialog>
</template>
  
<script>
import {
    lifeItemsSaveApi, lifeItemsLisyApi, lifeItemsDeleteApi, lifeItemsSelectByApi
} from "@/api/life/life/lifeItems";
export default {
    data() {
        return {
            options: [{
                value: '658065025308889088',
                label: '常用单位组',
                children: [{
                    value: '658065078647853056',
                    label: '个'
                }, {
                    value: '658065112143564800',
                    label: '瓶'
                },
                {
                    value: '658066013444968448',
                    label: '包'
                },
                {
                    value: '658066940587479041',
                    label: 'ml'
                },
                {
                    value: '658066940587479040',
                    label: '条'
                }]
            },],
            categoryList: [
                {
                    text: "酒类",
                    value: "xun.item.baseLiquor"
                },
                {
                    text: "茶类",
                    value: "xun.item.tea"
                },
                {
                    text: "菜类",
                    value: "xun.item.food"
                }, {
                    text: "家用",
                    value: "xun.item.household"
                },
                {
                    text: "宠物",
                    value: "xun.item.pets"
                },
                {
                    text: "零食",
                    value: "xun.item.snacks"
                },
                {
                    text: "厨房",
                    value: "xun.item.kitchen"
                },



            ],
            statusList: [
                {
                    label: "已购买",
                    value: "1"
                },
                {
                    label: "购买-未到",
                    value: "2"
                },
                {
                    label: "未购买",
                    value: "3"
                },
            ],
            typeList: [
                {
                    label: "购买",
                    value: "xun.life.shop"
                }
            ],
            itemForm: {},
            outerVisible: false,
            innerVisible: false,
            itemLiveList: [],
            queryParams: {},
            moreSwitch: false
        };
    },
    methods: {
        show() {
            this.outerVisible = true
            this.lifeItemsListButton();
        },
        lifeItemsListButton() {
            lifeItemsLisyApi(this.queryParams).then(res => {
                this.itemLiveList = res.result
            })
        },
        outerVisibleHandleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.outerVisibleButton();
                })
                .catch(_ => { });
        },
        outerVisibleButton() {
            this.queryParams = {}
            this.outerVisible = false;
            this.itemLiveList = []
            this.$parent.initAllListButton();
        },
        resetQuery() {
            this.queryParams = {}
        },
        moreButton() {
            this.moreSwitch = !this.moreSwitch
        },
        lifeItemsSaveButton() {
            if (this.itemForm.category == null) {
                return this.$message.error("请选择分类");
            }
            if (this.itemForm.unit == null) {
                return this.$message.error("请选择单位");
            }
            console.log(this.itemForm.unit)
            if (this.itemForm.unit instanceof Array) {
                this.itemForm.unit = this.itemForm.unit[1]
            }
            lifeItemsSaveApi(this.itemForm).then(res => {
                this.innerVisibleClear()
            })
        },
        innerVisibleClear() {
            this.innerVisible = false;
            this.itemForm = {}
            // this.lifeItemsListButton()
            this.moreSwitch = false
        },
        categoryFormat(row, column) {
            return this.selectDictLabel(this.categoryList, row.category);
        },
        innerVisibleHandleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.innerVisibleClear();
                })
                .catch(_ => { });
        },
        lifeItemsEditSaveButton(row) {
            this.innerVisible = true
            lifeItemsSelectByApi(row.id).then(res => {
                this.itemForm = res.result
            })
        },
        lifeItemsDeleteButton(row) {
            lifeItemsDeleteApi(row.id).then(res => {
                this.innerVisibleClear();
            })
        }

    }
}
</script>
<style  lang="less" scoped>
.el-cascader-menu {
    max-height: 300px;
    overflow-y: auto;
}
</style>